import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { useFormikContext } from "formik";
import { memo, useMemo } from "react";
import {
  warrantyPeriodUnits,
  warrantyTypes,
} from "utils/constants/productForm";
import isValidNumber from "utils/helpers/isValidNumber";

function ServiceSection() {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = useFormikContext();

  const WarrantyType = useMemo(
    () => (
      <Autocomplete
        name="warrantyType"
        value={values.warrantyType}
        options={warrantyTypes}
        onChange={(_, value) => {
          if (value) setFieldValue("warrantyType", value);
          else {
            setFieldValue("warrantyType", "none");
            setFieldValue("warrantyPeriod", "");
            setFieldValue("warrantyTenure", "");
          }
        }}
        onBlur={() => setFieldTouched("warrantyType", true)}
        renderInput={(params) => (
          <StyledTextField
            fullWidth
            {...params}
            placeholder="Please Select"
            error={Boolean(touched?.warrantyType && errors?.warrantyType)}
            helperText={
              touched?.warrantyType && !!errors?.warrantyType
                ? errors.warrantyType
                : "Select the warranty type"
            }
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option}
          </li>
        )}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      errors.warrantyType,
      touched?.warrantyType,
      values.warrantyType,
      isSubmitting,
    ]
  );

  const WarrantyPeriod = useMemo(
    () => (
      <Autocomplete
        name="warrantyPeriod"
        options={warrantyPeriodUnits}
        value={values.warrantyPeriod}
        onChange={(_, value) => {
          if (value) setFieldValue("warrantyPeriod", value);
          else {
            setFieldValue("warrantyPeriod", "");
            setFieldValue("warrantyTenure", "");
          }
        }}
        onBlur={() => setFieldTouched("warrantyPeriod", true)}
        disabled={
          !values?.warrantyType ||
          (values?.warrantyType && values?.warrantyType === "none")
        }
        renderInput={(params) => (
          <StyledTextField
            fullWidth
            {...params}
            placeholder="Please Select"
            error={Boolean(touched?.warrantyPeriod && errors?.warrantyPeriod)}
            helperText={
              touched?.warrantyPeriod && !!errors?.warrantyPeriod
                ? errors.warrantyPeriod
                : "Select the warranty period"
            }
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option}
          </li>
        )}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      errors.warrantyPeriod,
      touched?.warrantyPeriod,
      values.warrantyPeriod,
      values?.warrantyType,
      isSubmitting,
    ]
  );

  const WarrantyTenure = useMemo(
    () => (
      <StyledTextField
        fullWidth
        type="number"
        name="warrantyTenure"
        placeholder="Warranty Tenure"
        value={values.warrantyTenure}
        onChange={(e) =>
          isValidNumber(e.target.value) &&
          setFieldValue("warrantyTenure", e.target.value)
        }
        onBlur={() => setFieldTouched("warrantyTenure", true)}
        disabled={
          !values?.warrantyType ||
          (values?.warrantyType && values?.warrantyType === "none")
        }
        error={Boolean(touched?.warrantyTenure && errors?.warrantyTenure)}
        helperText={
          touched?.warrantyTenure && !!errors?.warrantyTenure
            ? errors.warrantyTenure
            : "Please select warranty tenure"
        }
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      errors.warrantyTenure,
      touched?.warrantyTenure,
      values.warrantyTenure,
      values?.warrantyType,
      isSubmitting,
    ]
  );

  return (
    <StyledMuiCard
      title="Product Warranty"
      subTitle=" Please select accurate warranty type and period if have for the customers"
    >
      <CardContent>
        <Stack direction="column" gap={3}>
          {/* Warranty Type */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Warranty Type
            </Typography>
            {WarrantyType}
          </Stack>

          {/* Warranty Period */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Warranty Period
            </Typography>
            {WarrantyPeriod}
          </Stack>

          {/* Warranty Tenure */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Warranty Tenure
            </Typography>
            {WarrantyTenure}
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

export default memo(ServiceSection);
