import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR, PRIMARY } from "styles/colors";
import FilterByCategory from "./FilterByCategory";
import FilterByStore from "./FilterByStore";

export default function VideosFilter() {
  // const params = useQueryParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { userRole } = useSelector((state) => state?.auth);

  const toggle = () => {
    setOpen(!open);
    if (open) {
      navigate(`/videos`);
    }
  };

  // const handleChange = useCallback(
  //   (range) => {
  //     let _params = params;
  //     _params.from = range.startDate.toDateString();
  //     _params.to = range.endDate.toDateString();
  //     navigate({
  //       search: createSearchParams(_params).toString(),
  //     });
  //   },
  //   [navigate, params]
  // );

  return (
    <Box sx={containerStyles}>
      {userRole === "admin" && (
        <Box display="flex" gap={2} minHeight={46}>
          {!open ? (
            <Button
              variant="text"
              onClick={toggle}
              sx={{
                borderBottom: "2px solid",
                borderColor: PRIMARY,
                borderRadius: 0,
                textTransform: "none",
              }}
              className="Filter-toggle-btn"
            >
              Filter
            </Button>
          ) : (
            <>
              {/* <DateFilter onChange={handleChange} /> */}
              <FilterByStore />
              <FilterByCategory />
            </>
          )}

          {open && (
            <Button
              onClick={toggle}
              variant="text"
              className="Filter-toggle-btn"
              sx={{
                borderBottom: "2px solid #707070",
                borderRadius: 0,
                color: FILTER_TEXT_COLOR,
                textTransform: "none",
              }}
            >
              Clear All
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

const containerStyles = {
  "& .MuiFormControl-root": {
    borderColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#069ddd80",
    },
    "&:hover": {
      backgroundColor: "#edf8fd",
      "& fieldset": {
        borderColor: "#02b4fffc",
      },
    },
  },
  "& .MuiSelect-select": { height: "29px !important" },
  "& .Filter-toggle-btn": {
    height: 42,
    textTransform: "none",
    fontSize: "1rem",
  },
};
