import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DataGridSearch from "components/DataGridSearch";
import DataGrid from "components/Videos/DataGrid";
import UpdateFbViews from "components/Videos/UpdateFbViews";
import UploadVideoForm from "components/Videos/UploadVideoForm";
import EditVideoForm from "components/Videos/VideoForm/EditVideoForm";
import VideosFilter from "components/Videos/VideosFilter";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
function Videos({ upload, update }) {
  const userRole = useSelector((state) => state.auth?.userRole);
  if (update) {
    return <EditVideoForm />;
  }
  if (upload) {
    return <UploadVideoForm />;
  }
  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={1}
      >
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={600}
          paddingTop={1}
          paddingBottom={2}
        >
          Videos
        </Typography>
        <VideosFilter />
      </Box>
      <Box
        display="flex"
        justifyContent={userRole === "supplier" ? "space-between" : "flex-end"}
        gap={2}
      >
        {userRole === "supplier" && <UpdateFbViews />}
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="title"
          searchQueryParam="value"
        />
      </Box>
      <Box py={2}>
        <DataGrid />
      </Box>
    </Box>
  );
}
Videos.propTypes = {
  update: PropTypes.bool,
  upload: PropTypes.bool,
};

Videos.defaultProps = {
  update: false,
  upload: false,
};
export default Videos;
