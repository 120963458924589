import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomProductSearch from "components/DetailedDataGrid/CustomProductSearch";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { memo } from "react";
import VideoProductDataGrid from "./VideoProductDataGrid";

function ProductDetailSection({ formik }) {
  return (
    <StyledMuiCard
      title="Select Products"
      subTitle="Please select accurate product for showcasing against your video"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={1}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack
            direction="column"
            gap={1}
            maxHeight={600}
            sx={{ overflowY: "scroll", overflowX: "hidden" }}
            pt={{ xs: 1, md: 1.5 }}
          >
            <CustomProductSearch />
            <VideoProductDataGrid formik={formik} />
          </Stack>
          {formik.errors?.productsId && (
            <Typography variant="body1" color="error" mt={1}>
              {formik.errors?.productsId}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

ProductDetailSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ProductDetailSection, arePropsEqual);

function arePropsEqual(oldProps, newProps) {
  return (
    oldProps.formik.values.selectedProducts ===
      newProps.formik.values.selectedProducts &&
    oldProps.formik.errors.selectedProducts ===
      newProps.formik.errors.selectedProducts
  );
}
