import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { FastField, useFormikContext } from "formik";
import { memo } from "react";

const regions = {
  KSA: "ksa",
  PAK: "pak",
  CHINA: "china",
  ALL: "all",
};

const regionsArray = Object.entries(regions).map(([key, value]) => ({
  label: key,
  value: value,
}));

function ProductLocation() {
  return (
    <StyledMuiCard
      title="Product Locations"
      subTitle="Please select accurate product locations such as product origin and sales region."
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Product Origin
            </Typography>
            <OriginAutocompleteFastField />
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Sales Region
            </Typography>
            <RegionAutocompleteFastField />
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

export default memo(ProductLocation);

const OriginAutocompleteFastField = () => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <FastField name="origin">
      {({ meta }) => (
        <Autocomplete
          name="origin"
          options={regionsArray}
          value={
            regionsArray?.find((option) => option?.value === meta.value) || null
          }
          onChange={(_, region) => {
            setFieldValue("origin", region?.value || "");
          }}
          getOptionLabel={(option) => option?.label || ""}
          onBlur={() => setFieldTouched("origin", true)}
          isOptionEqualToValue={(option, value) =>
            option?.value === value?.value
          }
          renderInput={(params) => (
            <StyledTextField
              fullWidth
              placeholder="Select origin"
              {...params}
              error={Boolean(meta.touched && meta.error)}
              helperText={
                meta.touched && !!meta.error
                  ? meta.error
                  : "Please select origin of your product."
              }
            />
          )}
          renderOption={({ key, ...props }, option) => (
            <li {...props} key={key}>
              {option?.label}
            </li>
          )}
        />
      )}
    </FastField>
  );
};

const RegionAutocompleteFastField = () => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <FastField name="region">
      {({ meta }) => (
        <Autocomplete
          multiple
          name="region"
          options={regionsArray}
          value={meta.value.map((val) =>
            regionsArray?.find((region) => region?.value === val)
          )}
          onChange={(_, selectedRegions) => {
            const regionValues = selectedRegions?.map(
              (region) => region?.value
            );
            setFieldValue("region", regionValues);
          }}
          getOptionLabel={(option) => option?.label}
          onBlur={() => setFieldTouched("region", true)}
          isOptionEqualToValue={(option, value) =>
            option?.value === value?.value
          }
          renderInput={(params) => (
            <StyledTextField
              fullWidth
              placeholder="Select multiple regions"
              {...params}
              error={Boolean(meta.touched && meta.error)}
              helperText={
                meta.touched && !!meta.error
                  ? meta.error
                  : "Please select the country where you want your product to be sold."
              }
            />
          )}
          renderOption={({ key, ...props }, option) => (
            <li {...props} key={key}>
              {option?.label}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option?.label}
                {...getTagProps({ index })}
                key={option?.value}
              />
            ))
          }
        />
      )}
    </FastField>
  );
};
