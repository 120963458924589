import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetCategory } from "store/slices/categoriesSlice";
import { getSignature, saveUploadVideo } from "store/slices/videosSlice";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import TcVod from "vod-js-sdk-v6";
import * as Yup from "yup";
import BasicInformationSection from "./BasicInformationSection";
import ProductDetailSection from "./ProductDetailSection";
import VideoMediaSection from "./VideoMediaSection";

function UploadVideoForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const loading = useSelector(
    (state) => state?.videos?.videoUploading ?? false
  );

  useEffect(() => {
    if (!params.id) {
      dispatch(resetCategory());
    }
  }, [dispatch, params.id]);

  const initialValues = useMemo(
    () => ({
      title: "",
      thumbnail: "",
      video: "",
      category: null,
      productsId: [],
    }),
    []
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const tcVod = new TcVod({
          getSignature: async () => {
            try {
              const result = await dispatch(getSignature()).unwrap();
              return result;
            } catch (error) {
              return null;
            }
          },
        });

        const uploader = tcVod.upload({
          mediaFile: values?.video,
        });

        uploader.on("media_progress", () => {
          // Progress
        });

        const doneResult = await uploader.done();

        const formData = new FormData();
        formData.append("title", values?.title);
        formData.append("category", values?.category);
        formData.append("photo", values?.thumbnail);
        values?.productsId.forEach((item, index) => {
          formData.append(`productsId[${index}]`, item);
        });
        formData.append("streamingUrl", doneResult?.video?.url);
        formData.append("fileId", doneResult?.fileId);
        formData.append("token", localStorage.getItem("token"));
        dispatch(saveUploadVideo(formData))
          .unwrap()
          .then(() => {
            return navigate("/videos");
          });
      } catch (error) {
        const message = getErrorMessage(error);
        toast.error(message);
      }
    },
    [dispatch, navigate]
  );

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          VIDEOS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
        paddingBottom={2}
      >
        Video Details
      </Typography>

      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              {/* Video Basic Information */}
              <Grid item xs={12} lg={12}>
                <BasicInformationSection formik={formik} />
              </Grid>
              {/* Video Data Grid Section */}
              <Grid item xs={12} lg={12}>
                <ProductDetailSection formik={formik} />
              </Grid>
              {/* Video Media Section */}
              <Grid item xs={12} lg={12}>
                <VideoMediaSection formik={formik} />
              </Grid>
              {/* Video Submit section */}
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disabled={loading}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    {loading && <Skeleton />}
                    {loading ? "uploading..." : "Upload Video"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => formik.resetForm()}
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(UploadVideoForm);

const checkVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration / 60;
      resolve(duration <= 30);
    };

    video.onerror = () => {
      reject(new Error("Unable to load video metadata"));
    };

    video.src = URL.createObjectURL(file);
  });
};

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Title should be at least 10 characters long")
    .max(100, "Title is too long"),
  thumbnail: Yup.mixed().required("Thumbnail is required"),
  video: Yup.mixed()
    .required("Video file is required")
    .test("fileSize", "Video size should be 500MB or less", (file) => {
      return file && file.size <= 500 * 1024 * 1024; // 500MB in bytes
    })
    .test(
      "fileDuration",
      "Video duration should be 30 minutes or less",
      async (file) => {
        if (!file) return false;
        try {
          return await checkVideoDuration(file);
        } catch (error) {
          return false;
        }
      }
    ),
  productsId: Yup.array()
    .required("Required*")
    .min(1, "Select at least one product"),
  category: Yup.object().nullable(),
});
